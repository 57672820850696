import { Controller } from 'stimulus';

const CLASSES = {
  'HIDDEN': 'hidden',
};

export default class extends Controller {
  static targets = ['button', 'loader']
  defaultSeconds = 2;

  /**
   * Stimulus method called when controller
   * is connected to the DOM
   *
   * @method connect
   * @public
   */
  connect() {
    if (this.fetchOnLoad) {
      this.loadingFetch();
    }
  }

  /**
   * Send fetch request
   *
   * @method loadingFetch
   * @public
   */
  loadingFetch() {
    const startTime = Date.now();

    fetch(this.url, {
      'method': this.method,
      'headers': {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          setTimeout(() => {
            response.json().then(data => {
              Turbolinks.visit(data.url, { action: 'replace' });
            });
          }, this.timeLeft(startTime));
        } else {
          response.json().then(data => {
            Turbolinks.visit(data.url, { action: 'replace' });
          });
        }
      });
  }

  /**
   * Hide button, show loader, and resend fetch request
   *
   * @method resubmit
   * @public
   */
  resubmit() {
    this.buttonTarget.classList.add(CLASSES.HIDDEN);
    this.loaderTarget.classList.remove(CLASSES.HIDDEN);
    this.loadingFetch();
  }

  /**
   * Retrieves the url to fetch
   * @return {String} Url
   */
  get url() {
    return this.data.get('url');
  }

  /**
   * Returns true if fetch on page load
   * @return {Boolean} fetch-on-load
   */
  get fetchOnLoad() {
    return this.data.get('fetch-on-load') === 'true';
  }

  /**
   * Retrieves the method for the fetch
   * @return {String} method
   */
  get method() {
    return this.data.get('method') || 'POST';
  }

  /**
   * Retrieves the CSRF token for the fetch
   * @return {String} method
   */
  get csrfToken() {
    return document.querySelector("[name='csrf-token']").content;
  }

  /**
   * Retrieves seconds to run if set in data attribute
   * @return {String} minLoadTime
   */
  get minLoadTime() {
    return (this.data.get('min-load-time') || this.defaultSeconds) * 1000;
  }

  /**
   * Returns calculated time left to show the loader animation
   * @return {String} timeLeft
   */
  timeLeft(startTime) {
    const currentTime = Date.now();
    const elapsedTime = currentTime - startTime;
    const timeLeft = this.minLoadTime - elapsedTime;

    return timeLeft > 0 ? timeLeft : 0;
  }
}
